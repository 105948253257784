class ApiService {
  apiUrl = window.location.origin + "/amazon-valuation-tool/tool";

  continueLaterUrl = window.location.origin + "/amazon-valuation-tool/continue-later";



  submitForm(answers, updateState) {

    const event = new CustomEvent("valuationFormSubmit");
    window.dispatchEvent(event);

    fetch(this.apiUrl, {
      method: "POST",
      body: JSON.stringify(answers)
    })
      .then(handleErrors)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {

        sessionStorage.clear();

        if (data.status === "success") {
          window.location.href = window.location.origin + "/amazon-valuation-tool/v/" + data.ref;
        } else {
          window.location.href = window.location.origin + "/amazon-valuation-tool/oops";
        }

      })
      .catch(function (response) {
        updateState({
          error: true
        });

        const event = new CustomEvent("valuationFormError");
        window.dispatchEvent(event);

        sessionStorage.clear();
      });
  }

  continueLater(answers, email) {

    return new Promise((resolve, reject) => {

      fetch(this.continueLaterUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          answers: answers,
          email: email
        })
      })
        .then(handleErrors)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {

          resolve();

        })
        .catch(function (response) {

          reject();
        });
    });
  }
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const _ApiService = new ApiService();
export default _ApiService;
