import React from 'react';
import styles from './errorscreen.module.scss';

export default class ErrorScreen extends React.PureComponent {

  render() {
    return (
      <div className={styles.container}>
        <h2 className={styles.headline}>Oops!</h2>
        <hr className={styles.divider}/>
        <br/>
        Looks like something went wrong. Our team has been notified and we're already looking into it.
      </div>
    );
  }
}
