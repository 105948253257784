import React from 'react';
import styles from './multiform.module.scss';
import StepContainer from '../../containers/StepContainer';

export default class Form extends React.PureComponent {

  render() {
    const { currentStep, onAnswerChange, answers, onStepChanged, onComplete, mistakenField } = this.props;

    return (
      <form className={styles.form}>
        <StepContainer answers={answers}
                       currentStep={currentStep}
                       onAnswerChange={onAnswerChange}
                       onStepChanged={onStepChanged}
                       onComplete={onComplete}
                       mistakenField={mistakenField}/>
      </form>
    );
  }
}