import React from 'react';
import styles from './radiofield.module.scss';
import empty from '../../utils/empty';
import SanitizedHTML from 'react-sanitized-html';

export default class MultiSelect extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  render() {
    const {name, value, options, question} = this.props;

    const optionValues = (!empty(value)) ? value.short : [];

    return (
      <div className={styles.container}>
        {options.map((option, index) =>
          (
            <Option key={`question_${name}_option_${index}`}
                    option={option}
                    index={index}
                    question={question}
                    selected={optionValues.includes(option.value)}
                    onSelect={this.onOptionSelected}/>
          )
        )}
      </div>
    );
  }

  onOptionSelected(value, longValue) {

    const current = !empty(this.props.value) ? this.props.value.short : null;
    const currentValues = (!empty(current) && current.length > 0) ? current : [];

    const currentLong = !empty(this.props.value) ? this.props.value.long : null;
    const currentValuesLong = (!empty(currentLong) && currentLong.length > 0) ? currentLong : [];

    if (currentValues.includes(value)) {
      const index = currentValues.indexOf(value);

      return this.props.onChange(this.props.question.id, {
        question: this.props.question.text,
        value: {
          short: currentValues.filter((_, i) => i !== index),
          long: currentValuesLong.filter((_, i) => i !== index),
        }
      });
    }

    this.props.onChange(this.props.question.id, {
      question: this.props.question.text,
      value: {
        short: [...currentValues, value],
        long: [...currentValuesLong, longValue],
      }
    });
  }
}

const Option = (props) => {
  const {option, index, selected, onSelect} = props;
  const image = !empty(option.image) ?
    (<div className={styles.image} style={{backgroundImage: `url('${option.image}')`}}/>) : null;

  const classes = classesConcat(option, selected);

  const selectOption = () => {
    onSelect(option.value, option.text);
  };

  return (
    <div className={`option-${index + 1} ${classes}`} onClick={selectOption}>
      {image}
      <SanitizedHTML
        allowedAttributes={{'a': ['href']}}
        allowedTags={['a', 'b', 'strong', 'i', 'u', 'br']}
        html={option.text}
      />
    </div>
  );
};

const classesConcat = (option, selected) => {
  let classes = styles.option;

  classes += (selected) ? ` ${styles.selected} selected` : "";
  classes += !empty(option.image) ? ` ${styles.hasImage}` : "";

  return classes;
};