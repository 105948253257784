import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styles from "../continue-later/continue-later.module.scss";
import { withStyles } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

const materialStyles = (theme) => ({
  margin: {
    margin: theme.spacing(),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertDialog extends React.Component {
  render() {
    const { open, onClose, classes, title, description } = this.props;

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.dialogContainer}>
          <DialogTitle
            id="alert-dialog-slide-title"
            className={styles.title}
            style={{ paddingTop: "1rem" }}
          >
            {title}
          </DialogTitle>
          <DialogContent className={styles.content}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              onClick={this.props.onClose}
              color="primary"
              className={styles.buttonSend}
            >
              Okay
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(materialStyles)(AlertDialog);
