import React from 'react';
import styles from './question.module.scss'
import empty from '../../utils/empty';
import Tooltip from './Tooltip';
import SanitizedHTML from 'react-sanitized-html';

export default class Question extends React.PureComponent {

  render() {
    const { question } = this.props;
    const tooltip = !empty(question.tooltip) ? <Tooltip text={question.tooltip} iconName={question.tooltipIcon}/> : null;

    return (
      <div className={styles.question} ref={`question-${question.id}`}>
        {tooltip}
        <h3 className={styles.title}>
          <SanitizedHTML
            allowedAttributes={{ 'a': ['href'], 'span': ['style'], 'div': ['style'], 'p': ['style'] }}
            allowedTags={['a', 'b', 'strong', 'i', 'u', 'br', 'div', 'span', 'p']}
            html={question.text}
          />
        </h3>
      </div>
    );
  }
}
