import SimpleReactValidator from 'simple-react-validator';
import React from 'react';
import Icon from '@material-ui/core/es/Icon/Icon';
import empty from '../utils/empty';

const validatorConfig = {
  element: message => {
    let messageText = message.split("---")[1];
    messageText = messageText.charAt(0).toUpperCase() + messageText.slice(1);
    messageText = messageText.replace('field', '');
    messageText = messageText.replace(/.$/, "!");
    messageText = messageText.slice(0, -1);

    let parts = messageText.split(' ');
    parts = parts.filter(x => !empty(x));

    let newMessage = "";

    for (let i = 0; i < parts.length; i++) {
      let part = parts[i];

      if (!isNaN(part)) {
        const number = Number(part);
        part = number.toLocaleString("en-US");
      }

      newMessage += part + " ";
    }

    newMessage += "!";
    newMessage = newMessage.charAt(0).toUpperCase() + newMessage.slice(1);

    return <div className="validation-error"><Icon className="validation-error-icon">warning</Icon>{newMessage}</div>
  },
  messages: {
    regex: 'The :attribute format is invalid!',
  }
};

class ValidationService {

  constructor() {
    this.validator = new SimpleReactValidator(validatorConfig);
  }

  addValidation(title, value, rules) {
    return this.validator.message(title, value, rules);
  }

  purgeFields() {
    this.validator.purgeFields();
  }

  validateState() {
    return this.validator.allValid();
  }

  showMessages() {
    this.validator.showMessages();
  }

  clearValidator() {
    this.validator = null;
    this.validator = new SimpleReactValidator(validatorConfig);
  }

  getErrors() {
    return this.validator.errorMessages;
  }
}

const _ValidationService = new ValidationService();
export default _ValidationService;
