import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./datefield.module.scss";
import empty from "../../utils/empty";
import MonthYearPicker from "react-month-year-picker";

const materialStyles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
});

class DateField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.year = null;
    this.month = null;

    this.onChangeYear = this.onChangeYear.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onDateSelected = this.onDateSelected.bind(this);
  }

  render() {
    const { value, additionalProps } = this.props;

    const dateValue = !empty(value) ? new Date(Date.parse(value)) : new Date();

    const selectedYear = dateValue.getFullYear();
    const selectedMonth = dateValue.getMonth() + 1;

    this.year = selectedYear;
    this.month = selectedMonth;

    let min = !empty(additionalProps.min) ? additionalProps.min : null;
    let max = !empty(additionalProps.max) ? additionalProps.max : null;

    const minDate = !empty(min)
      ? min === "today"
        ? new Date()
        : new Date(Date.parse(min))
      : null;
    const maxDate = !empty(max)
      ? max === "today"
        ? new Date()
        : new Date(Date.parse(max))
      : null;

    const minYear = !empty(minDate)
      ? parseInt(minDate.toISOString().slice(0, 4))
      : 1900;
    const maxYear = !empty(maxDate)
      ? parseInt(maxDate.toISOString().slice(0, 4))
      : 3000;

    return (
      <div className={styles.date}>
        <MonthYearPicker
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          minYear={minYear}
          maxYear={maxYear}
          onChangeYear={this.onChangeYear}
          onChangeMonth={this.onChangeMonth}
        />
      </div>
    );
  }

  onChangeYear(year) {
    this.year = year;
    this.onDateSelected();
  }

  onChangeMonth(month) {
    this.month = month;
    this.onDateSelected();

    this.props.onAnswer(this.props.question.id);
  }

  onDateSelected() {
    const { onChange, question } = this.props;

    const newValue = new Date();
    newValue.setFullYear(this.year, this.month - 1, 1);

    onChange(question.id, {
      question: question.text,
      value: newValue.toISOString(),
    });
  }
}

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(materialStyles)(DateField);
