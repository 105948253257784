import empty from '../utils/empty';
import apiService from './ApiService';
import validationService from './ValidationService';
import questions from '../data/questions.json';

export default class AdditionalLogicMiddleware {

  static completedSteps = [0];

  static onBeforeStepChanged(state, updateState) {

    const answers = state.answers;

    if (dateBefore365Days(answers) === true
      || topLineSalesBelow1000(answers) === true) {
      apiService.submitForm(answers, updateState);

      return true;
    }

  }

  static onAfterStepChanged(stepIndex, state, updateState) {

    if (!AdditionalLogicMiddleware.completedSteps.includes(stepIndex)) {

      AdditionalLogicMiddleware.completedSteps.push(stepIndex);

      const event = new CustomEvent("StepCompleted", {
        detail: {
          stepNumber: stepIndex,
          answers: state.answers
        }
      });
      window.dispatchEvent(event);
    }

    validationService.clearValidator();
  }

  static onBeforeAnswerChanged(state, updateState) {

  }

  static onAfterAnswerChanged(state, updateState) {
    validationService.clearValidator();
  }

  static onAfterComponentUpdated(state, updateState, saveState) {
    const noProgressQuestionKeys = questions.filter(question => question.updateProgress === false)
      .map(question => question.id);

    const noProgressQuestionsNumber = noProgressQuestionKeys.length;
    const questionsTotalNumber = questions.filter(q => q.progress !== false).length - noProgressQuestionsNumber;
    const answerKeys = Object.keys(state.answers).filter(x => !noProgressQuestionKeys.includes(Number(x)));
    const questionReached = answerKeys[answerKeys.length - 1];
    const progressPercent = (questionReached * 100) / questionsTotalNumber;

    if (state.percent !== progressPercent) {
      updateState({
        percent: progressPercent
      });
    }

    saveState();
  }
}


const dateBefore365Days = (answers) => {

  if (empty(answers) || empty(answers[2])) {
    return false;
  }

  const date = new Date(answers[2].value);
  const today = new Date();
  const beforeOneYear = new Date();
  beforeOneYear.setFullYear(today.getFullYear() - 1);

  return date > beforeOneYear;
};

const topLineSalesBelow1000 = (answers) => {

  if (empty(answers) || empty(answers[10])) {
    return false;
  }

  return answers[10].value < 1000;
};
