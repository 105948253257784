import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./checkboxfield.module.scss";
import SanitizedHTML from "react-sanitized-html";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "../../utils/muiTheme";

class CheckboxField extends React.Component {
  handleChange = () => {
    this.props.onChange(this.props.question.id, {
      question: this.props.question.text,
      value: !this.props.value,
    });
  };

  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <FormControlLabel
          className={styles.label}
          control={
            <Checkbox
              checked={this.props.value === true}
              onChange={this.handleChange}
              value={String(this.props.value === true)}
            />
          }
          label={
            <SanitizedHTML
              allowedAttributes={{ a: ["href", "target"] }}
              allowedTags={["a", "b", "strong", "i", "u", "br"]}
              html={this.props.question.label}
            />
          }
        />
      </MuiThemeProvider>
    );
  }
}

export default CheckboxField;
