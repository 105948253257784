import React from 'react';
import styles from './radiofield.module.scss';
import empty from '../../utils/empty';
import SanitizedHTML from 'react-sanitized-html';
import DependsOnService from '../../services/DependsOnService';

export default class RadioField extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  render() {
    const {name, value, options, answers} = this.props;

    return (
      <div className={styles.container}>
        {options.map((option, index) =>
          (
            <Option key={`question_${name}_option_${index}`}
                    option={option}
                    answers={answers}
                    index={index}
                    selected={!empty(value) && value.short === option.value}
                    onSelect={this.onOptionSelected}/>
          )
        )}
      </div>
    );
  }

  onOptionSelected(value, longValue) {
    this.props.onChange(this.props.question.id, {
      question: this.props.question.text,
      value: {
        short: value,
        long: longValue
      }
    });

    this.props.onAnswer(this.props.question.id);
  }
}

const Option = (props) => {
  const {option, index, selected, onSelect, answers} = props;
  const image = !empty(option.image) ?
    (<div className={styles.image} style={{backgroundImage: `url('${option.image}')`}}/>) : null;

  const classes = classesConcat(option, selected);

  const selectOption = () => {
    onSelect(option.value, option.text);
  };

  const shouldRender = DependsOnService.shouldOptionRender(option, answers);

  if (!shouldRender) return "";

  return (
    <div className={`option-${index + 1} ${classes}`} onClick={selectOption}>
      {image}
      <SanitizedHTML
        allowedAttributes={{'a': ['href']}}
        allowedTags={['a', 'b', 'strong', 'i', 'u', 'br']}
        html={option.text}
      />
    </div>
  );
};

const classesConcat = (option, selected) => {
  let classes = styles.option;

  classes += (selected) ? ` ${styles.selected} selected` : "";
  classes += !empty(option.image) ? ` ${styles.hasImage}` : "";

  return classes;
};
