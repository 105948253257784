import React from 'react';
import DateField from './DateField';
import RadioField from './RadioField';
import TextField from './TextField';
import empty from '../../utils/empty';
import MultiSelect from './MultiSelect';
import validationService from '../../services/ValidationService';
import CheckboxField from './CheckboxField';
import LazyLoadedComponent from '../custom/LazyLoadedComponent';

export default class Field extends React.PureComponent {

  render() {

    const {type, name, value, options, onChange, onAnswer, question, answers} = this.props;

    const additionalProps = !empty(question.additionalProps) ? question.additionalProps : [];

    let field = null;

    switch (type) {

      case "date":
        field = <DateField name={name}
                           question={question}
                           value={value}
                           onChange={onChange}
                           onAnswer={onAnswer}
                           additionalProps={additionalProps}/>;
        break;

      case "radio":
        field = <RadioField name={name}
                            question={question}
                            value={value}
                            options={options}
                            onChange={onChange}
                            onAnswer={onAnswer}
                            answers={answers}
                            additionalProps={additionalProps}/>;
        break;

      case "multiselect":
        field = <MultiSelect name={name}
                             question={question}
                             answers={answers}
                             value={value}
                             options={options}
                             onChange={onChange}
                             onAnswer={onAnswer}
                             additionalProps={additionalProps}/>;
        break;

      case "text":
        field = <TextField name={name}
                           question={question}
                           value={value}
                           options={options}
                           onChange={onChange}
                           onAnswer={onAnswer}
                           type="text"
                           additionalProps={additionalProps}/>;
        break;

      case "number":
        field = <TextField name={name}
                           question={question}
                           value={value}
                           options={options}
                           onChange={onChange}
                           onAnswer={onAnswer}
                           type="number"
                           additionalProps={additionalProps}/>;
        break;

      case "currency":
        field = <TextField name={name}
                           question={question}
                           value={value}
                           options={options}
                           onChange={onChange}
                           onAnswer={onAnswer}
                           type="number"
                           additionalProps={additionalProps}/>;
        break;

      case "checkbox":
        field = <CheckboxField name={name}
                               question={question}
                               value={value}
                               onChange={onChange}
                               onAnswer={onAnswer}
                               additionalProps={additionalProps}/>;
        break;

      case "custom":
        field = <LazyLoadedComponent name={name}
                                     question={question}
                                     value={value}
                                     options={options}
                                     onChange={onChange}
                                     onAnswer={onAnswer}
                                     answers={answers}
                                     additionalProps={additionalProps}
                                     resolve={() => import('../custom/' + question.customComponentPath)}/>;
        break;
      default:
        field = "";
    }


    let validationValue = null;
    let validationFieldTitle = `question_${question.id}_---this_answer`;

    if (!empty(question.validation)) {

      if (!empty(answers) && !empty(answers[question.id])) {
        validationValue = (answers[question.id].value.constructor === Object) ? answers[question.id].value.short : answers[question.id].value;
      }

      if (question.validation.indexOf('|') > -1) {
        const rules = question.validation.split('|');

        if (rules.includes('email')) {

          const emailRuleIndex = rules.findIndex(x => x === "email");
          const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          rules[emailRuleIndex] = {
            regex: regex
          };
          question.validation = rules;
        }
      }

      if (question.type === "text") {
        const name = `The ${question.label} field`.replace(" ", "_");
        validationFieldTitle = `question_${question.id}_---${name}`;
      }

      if (question.onlyField === true) {

        if (question.type === "checkbox") {
          const name = question.text.replace(" ", "_");
          validationFieldTitle = `question_${question.id}_---${name}`;
        }
      }

    }


    return (
      <div id={`question-${question.id}`}>
        {field}
        {!empty(question.validation) ? validationService.addValidation(validationFieldTitle, validationValue, question.validation) : null}
      </div>
    );
  }
}
