import SanitizedHTML from 'react-sanitized-html';
import React from "react";
import styles from './note.module.scss';

export default (note) => {

  return (
    <div className={styles.note}>
      <SanitizedHTML
        allowedAttributes={{'a': ['href']}}
        allowedTags={['a', 'b', 'span', 'strong', 'i', 'u', 'br']}
        html={note.text}
      />
    </div>
  );
}
