import React from 'react';
import styles from './thankyouscreen.module.scss';

export default class ThankYouScreen extends React.PureComponent {

  render() {
    return (
      <div className={styles.container}>
        <h2 className={styles.headline}>Thank you!</h2>
        <hr className={styles.divider}/>
        <br/>
        Thank you for your time! We are analyzing your answers and we will get back to you in no time!
      </div>
    );
  }
}
