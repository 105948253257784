import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import styles from './question.module.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';

export default class TooltipCustom extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
  }

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { text } = this.props;

    const iconName = this.props.iconName || "help_outline";

    return (

    <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            className: styles.tooltipMessage
          }}
          onClose={this.handleTooltipClose}
          onMouseEnter={this.handleTooltipOpen}
          onMouseLeave={this.handleTooltipClose}
          disableHoverListener
          disableTouchListener
          disableFocusListener
          open={this.state.open}
          title={text}
          className={styles.tooltip}
          placement="bottom-start"
        >
          <Icon onClick={this.handleTooltipOpen}>{iconName}</Icon>
        </Tooltip>
    </ClickAwayListener>
  );
  }
}
