import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SanitizedHTML from 'react-sanitized-html';
import styles from './intermission-dialog.module.scss';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class IntermissionDialog extends React.Component {

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {title, text, buttonText, open, onClose} = this.props;

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={styles.container}
      >
        <DialogTitle id="alert-dialog-slide-title" className={styles.title}>
          {title}
        </DialogTitle>
        <DialogContent>
          <SanitizedHTML
            allowedAttributes={{'a': ['href']}}
            allowedTags={['a', 'b', 'strong', 'i', 'u', 'br']}
            html={text}
            className={styles.text}
            id="alert-dialog-slide-description"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" className={styles.button}>
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default IntermissionDialog;
