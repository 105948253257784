import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styles from "./continue-later.module.scss";
import stylesContact from "../field/contactfield.module.scss";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SimpleReactValidator from "simple-react-validator";

const materialStyles = (theme) => ({
  margin: {
    margin: theme.spacing(),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EmailDialog extends React.Component {
  constructor(props) {
    super(props);

    const validatorConfig = {
      element: (message) => {
        return <div className={styles.validationError}>{message}</div>;
      },
      messages: {
        regex: "The :attribute format is invalid!",
      },
      autoForceUpdate: this,
    };

    this.validator = new SimpleReactValidator(validatorConfig);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
  }

  handleEmailChange = (event) => {
    this.props.onEmailChange(event.target.value);
  };

  handleEmailBlur() {
    this.validator.showMessageFor("email");
  }

  handleSubmit() {
    if (this.validator.allValid()) {
      this.props.onSubmit();
    } else {
      this.validator.showMessages();
    }
  }

  render() {
    const { open, onClose, classes, email } = this.props;

    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const DialogCustomTitle = withStyles(styles)((props) => {
      const { children, classes, onClose } = props;
      return (
        <DialogTitle disableTypography className={props.className}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      );
    });

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.dialogContainer}>
          <DialogCustomTitle
            id="alert-dialog-slide-title"
            className={styles.title}
            onClose={this.props.onClose}
          >
            Continue later?
          </DialogCustomTitle>
          <DialogContent className={styles.content}>
            <DialogContentText>
              To continue answering this form later, please enter the email
              address you would like to send the link to:
            </DialogContentText>
            <TextField
              id="email"
              name="email"
              value={this.props.email}
              variant="outlined"
              onChange={this.handleEmailChange}
              onBlur={this.handleEmailBlur}
              type="email"
              label="Email"
              placeholder="Your E-mail"
              className={`${stylesContact.contactField} ${
                stylesContact.email
              } ${styles.email} ${classNames(
                classes.margin,
                classes.textField
              )}`}
            />
            <div className={styles.validationContainer}>
              {this.validator.message("email", this.props.email, [
                "required",
                { regex: emailRegex },
              ])}
            </div>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              className={styles.buttonSend}
            >
              Send Link
            </Button>
            {/*<Button onClick={this.handleClose} variant="flat" color="secondary" className={styles.buttonCancel}>*/}
            {/*  Cancel*/}
            {/*</Button>*/}
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(materialStyles)(EmailDialog);
