import questions from '../data/questions.json';
import steps from '../data/steps.json';
import React from 'react';
import Step from '../components/step/Step';
import empty from '../utils/empty';

export default class StepContainer extends React.PureComponent{

  render() {
    const { currentStep, onAnswerChange, answers, onStepChanged, onComplete, mistakenField } = this.props;

    const stepConfig = !empty(steps[currentStep]) ? steps[currentStep] : [];
    const questionsIds = !empty(stepConfig.questions) ? stepConfig.questions : [];
    const stepQuestions = questionsIds.length > 0 ? questions.filter(x => questionsIds.includes(x.id)) : [];
    const headline = !empty(stepConfig.headline) ? stepConfig.headline : "";
    const note = !empty(stepConfig.note) ? stepConfig.note : null;

    return <Step headline={headline}
                 questions={stepQuestions}
                 answers={answers}
                 onAnswerChange={onAnswerChange}
                 firstStep={currentStep === 0}
                 lastStep={currentStep === steps.length - 1}
                 currentStep={currentStep}
                 onStepChanged={onStepChanged}
                 note={note}
                 onComplete={onComplete}
                 mistakenField={mistakenField}
    />
  }
}