import React, { Fragment } from "react";
import styles from "./step.module.scss";
import Question from "../question/Question";
import Field from "../field/Field";
import empty from "../../utils/empty";
import Button from "@material-ui/core/Button/Button";
import DependsOnService from "../../services/DependsOnService";
import Note from "../note/Note";
import questionsData from "../../data/questions";

export default class Step extends React.PureComponent {
  constructor(props) {
    super(props);

    this.goToNextStep = this.goToNextStep.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onAnswer = this.onAnswer.bind(this);
  }

  render() {
    const {
      questions,
      headline,
      onAnswerChange,
      answers,
      firstStep,
      lastStep,
      onComplete,
      note,
      mistakenField,
    } = this.props;

    const noteComponent = !empty(note) ? <Note text={note} /> : null;

    let showNextButton = false;

    let dependentQuestions = questions.filter((x) => !empty(x.dependsOn));

    let inactiveQuestions = dependentQuestions.filter((x) => {
      if (x.disabled === true) {
        return true;
      }

      const answer = answers[x.dependsOn.question];

      if (empty(answer)) {
        return true;
      }

      const requiredValue = x.dependsOn.answer;

      if (answer.value.constructor === Object && !empty(answer.value.short)) {
        return answer.value.short !== requiredValue;
      } else {
        return answer.value !== requiredValue;
      }
    });

    const neededQuestions = questions.length - inactiveQuestions.length;

    const questionFields = questions.map((question, questionIndex) => {
      const classes = classesConcat(question);

      // Check if previous ACTIVE question is answered
      const inactiveQuestionKeys = inactiveQuestions.map((x) => x.id);
      const activeQuestions = questions.filter(
        (x) => !inactiveQuestionKeys.includes(x.id) && x.disabled !== true
      );
      const activeQuestionKeys = activeQuestions
        .map((x) => x.id)
        .filter((a) => a < question.id);
      const lastActiveQuestionKey =
        activeQuestionKeys[activeQuestionKeys.length - 1];

      if (question.id > 1 && questionIndex > 0) {
        if (empty(answers) || empty(answers[lastActiveQuestionKey])) {
          return "";
        }
      }

      const shouldRender = DependsOnService.shouldFieldRender(
        question,
        answers
      );

      if (!shouldRender) return "";

      if (
        questionIndex + 1 >= neededQuestions &&
        !empty(answers[question.id])
      ) {
        showNextButton = true;
      }

      if (!empty(mistakenField)) {
        const ref = `question-${mistakenField}`;

        // if (!empty(this.refs) && !empty(this.refs[ref])) {
        //   this.refs[ref].scrollIntoView({ behavior: "smooth", block: "start" });
        // }
      }

      const questionField =
        empty(question.onlyField) || question.onlyField === false ? (
          <Question question={question} />
        ) : null;

      return (
        <div
          key={`question_container_${question.id}`}
          ref={`question-${question.id}`}
        >
          {questionField}

          <div className={classes}>
            <Field
              name={`question_${question.id}`}
              question={question}
              answers={answers}
              type={question.type}
              options={question.options}
              onChange={onAnswerChange}
              onAnswer={this.onAnswer}
              value={
                !empty(answers[question.id]) ? answers[question.id].value : null
              }
            />
          </div>
        </div>
      );
    });

    const btnOne = showNextButton ? (
      lastStep ? (
        <div>
          <Button
            onClick={onComplete}
            className={`${styles.button} ${styles.btnOne}`}
            variant="contained"
            color="primary"
          >
            Let's do this!
          </Button>
        </div>
      ) : (
        <div ref="button-next">
          <Button
            onClick={this.goToNextStep}
            className={`${styles.button} ${styles.btnOne}`}
            variant="contained"
          >
            Next
          </Button>
        </div>
      )
    ) : null;

    const btnTwo = !firstStep ? (
      <Button
        onClick={this.goBack}
        className={`${styles.button} ${styles.btnTwo}`}
        variant="contained"
        color="secondary"
      >
        Back
      </Button>
    ) : null;

    const bottomReferer = <div ref="bottom-referer" />;

    return (
      <Fragment>
        <div ref="step" className={styles.step}>
          <h2 className={styles.headline}>{headline}</h2>
          <hr className={styles.divider} />
          {noteComponent}
          <div className={styles.fields}>
            {questionFields}
            {bottomReferer}
          </div>
        </div>
        {btnOne}
        {btnTwo}
      </Fragment>
    );
  }

  onAnswer(questionId) {
    setTimeout(() => {
      if (!empty(this.refs)) {
        const totalQuestions = questionsData.length;

        // for (let i = questionId; i < totalQuestions; i++) {
        //   if (!empty(this.refs[`question-${i + 1}`])) {
        //     this.refs[`question-${i + 1}`].scrollIntoView({
        //       behavior: "smooth",
        //       block: "start",
        //     });
        //     return;
        //   }
        // }

        // if (!empty(this.refs["button-next"])) {
        //   this.refs["button-next"].scrollIntoView({
        //     behavior: "smooth",
        //     block: "start",
        //   });
        // }
      }
    }, 50);
  }

  goToNextStep() {
    this.props.onStepChanged(this.props.currentStep + 1);
  }

  goBack() {
    this.props.onStepChanged(this.props.currentStep - 1);
  }
}

const classesConcat = (question) => {
  let classes = styles.field;
  const typeStyle = !empty(styles[question.type]) ? styles[question.type] : "";

  classes += !empty(typeStyle) ? ` ${styles[question.type]}` : "";

  return classes;
};
