import React, { Component } from "react";
import "./App.css";
import Navigation from "./components/navigation/Navigation";
import Form from "./components/multiform/MultiForm";
import steps from "./data/steps.json";
import AdditionalLogicMiddleware from "./services/AdditionalLogicMiddleware";
import validationService from "./services/ValidationService";
import empty from "./utils/empty";
import ThankYouScreen from "./components/thankyouscreen/ThankYouScreen";
import IntermissionDialog from "./components/intermission/IntermissionDialog";
import apiService from "./services/ApiService";
import ErrorScreen from "./components/errorscreen/ErrorScreen";
import ProgressBar from "./components/progressbar/ProgressBar";
import ContinueLater from "./components/continue-later/ContinueLater";
import EmailDialog from "./components/continue-later/EmailDialog";
import AlertDialog from "./components/alert-dialog/AlertDialog";
import getUrlParameter from "./utils/getUrlParameter";
import JSON5 from "json5";
import questions from "./data/questions";

const storageKey = "AppState";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      maximumStepReached: 0,
      answers: {},
      completed: false,
      percent: 0,
      mistakenField: null,
      intermissionOpen: false,
      intermissionsShown: [],
      intermissionTitle: "",
      intermissionText: "",
      intermissionButtonText: "",
      emailDialogOpen: false,
      continueLaterEmail: "",
      alertDialogOpen: false,
      alertDialogTitle: "",
      alertDialogDescription: "",
      error: false,
    };

    this.onStepChanged = this.onStepChanged.bind(this);
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onIntermissionDialogClose = this.onIntermissionDialogClose.bind(this);
    this.saveState = this.saveState.bind(this);
    this.getStateFromStorage = this.getStateFromStorage.bind(this);
    this.onContinueLaterPressed = this.onContinueLaterPressed.bind(this);
    this.onContinueLaterEmailChanged = this.onContinueLaterEmailChanged.bind(
      this
    );
    this.onContinueLaterSubmit = this.onContinueLaterSubmit.bind(this);
    this.onContinueLaterClose = this.onContinueLaterClose.bind(this);
    this.openAlertDialog = this.openAlertDialog.bind(this);
    this.onAlertDialogClose = this.onAlertDialogClose.bind(this);
  }

  componentWillMount() {
    /**
     * Check if this is a 'continue later' scenario
     */

    const answers = getUrlParameter("answers");

    if (answers) {
      try {
        const answersJson = JSON5.parse(answers);

        let answersState = {};

        let lastKey = 0;

        for (const key of Object.keys(answersJson)) {
          const question = questions.find((q) => q.id === Number(key));
          let answerValue = answersJson[key];

          if (question.options) {
            if (question.type === "multiselect") {
              answerValue = {
                short: answerValue,
                long: question.options
                  .filter((o) => answerValue.includes(o.value.toLowerCase()))
                  .map((o) => o.text),
              };
            } else {
              answerValue = {
                short: question.options.find(
                  (o) => o.value.toLowerCase() === answerValue.toLowerCase()
                ).value,
                long: question.options.find(
                  (o) => o.value.toLowerCase() === answerValue.toLowerCase()
                ).text,
              };
            }
          }

          answersState[key] = {
            question: question.text,
            value: answerValue,
          };

          lastKey = Number(key);
        }

        let currentStep = steps.findIndex((step) =>
          step.questions.includes(lastKey)
        );
        currentStep = currentStep !== -1 ? currentStep : 0;

        sessionStorage.setItem(
          storageKey,
          JSON.stringify({
            ...this.state,
            answers: answersState,
            currentStep: currentStep,
            maximumStepReached: currentStep,
          })
        );
      } catch (e) {
        console.error(e);
      }
    }

    /** Get state from storage **/

    const sessionState = this.getStateFromStorage();

    if (!empty(sessionState)) {
      this.setState(sessionState);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    AdditionalLogicMiddleware.onAfterComponentUpdated(
      this.state,
      this.updateState,
      this.saveState
    );
  }

  saveState() {
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({ ...this.state, completed: false })
    );
  }

  getStateFromStorage() {
    return JSON.parse(sessionStorage.getItem(storageKey));
  }

  render() {
    if (this.state.completed) return <ThankYouScreen />;
    if (this.state.error) return <ErrorScreen />;

    return (
      <React.Fragment>
        <div className="App">
          <Navigation
            items={steps}
            currentStep={this.state.currentStep}
            maximumStep={this.state.maximumStepReached}
            onStepChanged={this.onStepChanged}
            percent={this.state.percent}
          />
          <div id="form-container" ref="form">
            <Form
              answers={this.state.answers}
              currentStep={this.state.currentStep}
              onAnswerChange={this.onAnswerChange}
              onStepChanged={this.onStepChanged}
              onComplete={this.onComplete}
              mistakenField={this.state.mistakenField}
            />
          </div>
          <IntermissionDialog
            open={this.state.intermissionOpen}
            onClose={this.onIntermissionDialogClose}
            title={this.state.intermissionTitle}
            text={this.state.intermissionText}
            buttonText={this.state.intermissionButtonText}
          />
        </div>
        <div style={{ position: "sticky", bottom: 0, background: "white" }}>
          <ProgressBar percent={this.state.percent} />
          <ContinueLater
            answers={this.state.answers}
            onContinueLater={this.onContinueLaterPressed}
          />
        </div>

        <EmailDialog
          open={this.state.emailDialogOpen}
          email={this.state.continueLaterEmail}
          onSubmit={this.onContinueLaterSubmit}
          onClose={this.onContinueLaterClose}
          onEmailChange={this.onContinueLaterEmailChanged}
        />

        <AlertDialog
          open={this.state.alertDialogOpen}
          onClose={this.onAlertDialogClose}
          title={this.state.alertDialogTitle}
          description={this.state.alertDialogDescription}
        />
      </React.Fragment>
    );
  }

  onStepChanged(stepIndex) {
    if (
      stepIndex > this.state.currentStep &&
      validationService.validateState() &&
      !empty(steps[stepIndex]) &&
      !this.state.intermissionsShown.includes(stepIndex)
    ) {
      const stepConfig = steps[stepIndex];

      if (!empty(stepConfig) && !empty(stepConfig.intermission)) {
        const intermissionConfig = stepConfig.intermission;

        this.setState({
          intermissionOpen: true,
          intermissionsShown: [...this.state.intermissionsShown, stepIndex],
          intermissionTitle: intermissionConfig.title,
          intermissionText: intermissionConfig.text,
          intermissionButtonText: intermissionConfig.button,
        });
      }
    }

    if (
      stepIndex > this.state.currentStep &&
      !validationService.validateState()
    ) {
      validationService.showMessages();

      const errorMessages = validationService.getErrors();

      const filtered = Object.entries(errorMessages)
        .filter(([name, value]) => value !== null)
        .map(([name, value]) => ({ name, value }));

      if (!empty(filtered)) {
        const firstErrorField = filtered[0].name;
        const firstErrorAnswer = firstErrorField.match(/\d/g).join("");

        if (!empty(firstErrorAnswer)) {
          this.setState({
            mistakenField: firstErrorAnswer,
          });
        }
      }

      this.setState({
        answers: { ...this.state.answers },
      });

      return;
    }

    const breakBefore = AdditionalLogicMiddleware.onBeforeStepChanged(
      this.state,
      this.updateState
    );

    if (breakBefore) {
      return;
    }

    this.setState({
      currentStep: stepIndex,
      maximumStepReached:
        stepIndex > this.state.maximumStepReached
          ? stepIndex
          : this.state.maximumStepReached,
    });

    if (!empty(this.refs) && !empty(this.refs.form)) {
      this.refs.form.scrollIntoView({ behavior: "instant", block: "start" });
      window.scrollTo(0, window.pageYOffset - 74);
    }

    AdditionalLogicMiddleware.onAfterStepChanged(
      stepIndex,
      this.state,
      this.updateState
    );
    this.saveState();
  }

  onAnswerChange(questionId, value) {
    const breakBefore = AdditionalLogicMiddleware.onBeforeAnswerChanged(
      this.state,
      this.updateState
    );

    if (breakBefore) return;

    this.setState({
      answers: { ...this.state.answers, [questionId]: value },
    });

    AdditionalLogicMiddleware.onAfterAnswerChanged(
      this.state,
      this.updateState
    );
    this.saveState();
  }

  onComplete() {
    if (!validationService.validateState()) {
      validationService.showMessages();

      const errorMessages = validationService.getErrors();

      const filtered = Object.entries(errorMessages)
        .filter(([name, value]) => value !== null)
        .map(([name, value]) => ({ name, value }));

      if (!empty(filtered)) {
        const firstErrorField = filtered[0].name;
        const firstErrorAnswer = firstErrorField.match(/\d/g).join("");

        if (!empty(firstErrorAnswer)) {
          this.setState({
            mistakenField: firstErrorAnswer,
          });
        }
      }

      this.setState({
        answers: { ...this.state.answers },
      });

      return;
    }

    const stepEvent = new CustomEvent("StepCompleted", {
      detail: {
        stepNumber: 9,
        answers: this.state.answers,
      },
    });

    window.dispatchEvent(stepEvent);

    apiService.submitForm(this.state.answers, this.updateState);

    sessionStorage.clear();
  }

  updateState(newState) {
    this.setState({ ...newState });
  }

  onIntermissionDialogClose() {
    this.setState({
      intermissionOpen: false,
    });
  }

  onContinueLaterPressed() {
    this.setState({
      emailDialogOpen: true,
    });
  }

  onContinueLaterEmailChanged(email) {
    this.setState({
      continueLaterEmail: email,
    });
  }

  onContinueLaterSubmit() {
    apiService
      .continueLater(this.state.answers, this.state.continueLaterEmail)
      .then(() => {
        this.openAlertDialog(
          "Link sent!",
          `We've sent you your unique link to ${this.state.continueLaterEmail}.`
        );
      })
      .catch(() => {
        this.openAlertDialog(
          "Oops! Something went wrong...",
          `Sorry, we couldn't process your request.`
        );
      });

    this.setState({
      emailDialogOpen: false,
    });
  }

  onContinueLaterClose() {
    this.setState({
      emailDialogOpen: false,
    });
  }

  openAlertDialog(title, description) {
    this.setState({
      alertDialogTitle: title,
      alertDialogDescription: description,
      alertDialogOpen: true,
    });
  }

  onAlertDialogClose() {
    this.setState({
      alertDialogTitle: "",
      alertDialogDescription: "",
      alertDialogOpen: false,
    });
  }
}

export default App;
