import React from "react";
import { Line } from "rc-progress";
import styles from "./progressbar.module.scss";
import empty from "../../utils/empty";

const strokeWidth = "1";
const strokeColor = "#ff9900";
const trailColor = "#f2f2f2";

export default class ProgressBar extends React.PureComponent {
  render() {
    const { percent } = this.props;

    const progressPercent = !empty(percent) ? Math.ceil(percent) : 0;

    if (progressPercent === 0) {
      return null;
    }

    return (
      <div className={styles.progress}>
        <div className={styles.label}>
          Progress: <span>{progressPercent}%</span>
        </div>
        <Line
          percent={progressPercent}
          strokeWidth={strokeWidth}
          strokeColor={strokeColor}
          trailColor={trailColor}
        />
      </div>
    );
  }
}
