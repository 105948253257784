import React from 'react';
import styles from './navigation.module.scss';
import NavigationItem from "./NavigationItem";

export default class Navigation extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderListItems = this.renderListItems.bind(this);
    this.onStepChanged = this.onStepChanged.bind(this);
  }

  render() {

    return (
      <div className={styles.navigationContainer}>
        <ul className={styles.sidebar}>
          {this.renderListItems()}
        </ul>
      </div>

    );
  }

  renderListItems() {
    const {items, currentStep, maximumStep} = this.props;

    return items.map(item => {

      return <NavigationItem
        key={`navigation_item_${items.indexOf(item)}`}
        item={item}
        index={items.indexOf(item)}
        maximumStep={maximumStep}
        currentStep={currentStep}
        active={items.indexOf(item) === currentStep}
        onClick={this.onStepChanged}
      />
    });
  }

  onStepChanged(stepIndex) {
    this.props.onStepChanged(stepIndex);
  }

}
