import empty from '../utils/empty';

export default class DependsOnService {

  static shouldFieldRender(question, answers) {

    return this._shouldRender(question, answers);
  }

  static shouldOptionRender(option, answers) {
    return this._shouldRender(option, answers);
  }

  static _shouldRender(item, answers) {

    const dependsOnConfig = !empty(item.dependsOn) ? item.dependsOn : null;

    if (!empty(dependsOnConfig)) {

      const dependsOnQuestionNumber = dependsOnConfig.question;
      let answer = {...answers[dependsOnQuestionNumber]};

      if (empty(answer)) {
        return false;
      }

      answer = answer.value;

      if (empty(answer)) {
        return false;
      }

      if (typeof answer === "object" && answer.constructor === Object) {
        answer = answer.short;
      }

      const rule = dependsOnConfig.answer;

      if (empty(rule)) {
        return true;
      }

      const parts = rule.split(':');

      if (parts.length === 1 && parts[0] !== answer) {

        return false;

      } else if (parts.length > 1) {
        const condition = parts[0];
        const value = parts[1];

        switch (condition) {
          case "greater":
            if (value >= answer) return false;
            break;
          case "less":
            if (value < answer) return false;
            break;
          case "less_or_equal":
            if (value <= answer) return false;
            break;
          case "not_equal":
            if (value === answer || parseInt(value) === parseInt(answer)) return false;
            break;
          default:
            if (value !== answer || parseInt(value) !== parseInt(answer)) return false;
            break;
        }
      }
    }

    return true;
  }
}
