import React from 'react';
import styles from './continue-later.module.scss';
import Button from '@material-ui/core/Button';

class ContinueLater extends React.Component {

  render() {

    const {answers} = this.props;

    if (!answers || Object.keys(answers).length === 0) {
      return null;
    }

    return (
      <div className={styles.container}>
        <Button onClick={this.props.onContinueLater}
                variant="outlined"
                color="primary">Continue Later</Button>
      </div>
    );
  }
}

export default ContinueLater;
