import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  palette: {
    primary: {
      light: "#ffbd00",
      main: "#ff9900",
      dark: "#d67b00",
    },
    secondary: {
      light: "#4d6680",
      main: "#232f3e",
      dark: "#121a25",
    },
    primary2Color: "#ff9900",
    checkbox: {
      checkedColor: "#ff9900",
    },
  },
  typography: {
    useNextVariants: true,
  },
  checkbox: {
    checkedColor: "#ff9900",
  },
});
