import React from 'react';
import styles from './navigation-item.module.scss';

export default class NavigationItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.selectItem = this.selectItem.bind(this);
  }

  render() {
    const {item, active, index, maximumStep} = this.props;
    const locked = index > maximumStep;
    const classes = classesConcat(item, active, locked);

    return (
      <li className={classes} onClick={this.selectItem}>
        <span className={styles.number}>{index + 1}</span><span className={styles.dot}>.</span> {item.title}
      </li>
    );
  }

  selectItem() {
    const {index, maximumStep, currentStep, onClick} = this.props;

    if (index <= maximumStep && index !== currentStep) {
      onClick(index);
    }

  }
}

const classesConcat = (item, active, locked) => {
  let classes = styles.item;

  classes += (active) ? ` ${styles.active}` : "";
  classes += (locked) ? ` ${styles.locked}` : "";

  return classes;
};
