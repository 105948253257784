import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import empty from "../../utils/empty";
import styles from "./textfield.module.scss";
import NumberFormat from "react-number-format";

const materialStyles = (theme) => ({
  margin: {
    margin: theme.spacing(),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
  },
});

class FormTextField extends React.Component {
  handleChange = (event) => {
    const { onChange, question, type } = this.props;
    onChange(question.id, {
      question: question.text,
      value:
        (type === "number" || type === "currency") && !empty(event.target.value)
          ? parseFloat(event.target.value)
          : event.target.value,
    });
  };

  onBlur = (event) => {
    this.props.onAnswer(this.props.question.id);
  };

  render() {
    const {
      classes,
      name,
      value,
      question,
      type,
      additionalProps,
    } = this.props;
    const textValue = !empty(value) ? value : "";

    let min = !empty(additionalProps.min) ? additionalProps.min : null;
    let max = !empty(additionalProps.max) ? additionalProps.max : null;

    const inputProps = {};

    if (type === "number" || type === "currency") {
      inputProps.inputComponent = NumberFormatCustom;
    }

    inputProps.inputProps = {
      min: min,
      max: max,
    };

    const adornmentConfig = !empty(additionalProps.adornment)
      ? additionalProps.adornment
      : null;

    if (!empty(adornmentConfig)) {
      if (adornmentConfig.position === "start") {
        inputProps.startAdornment = (
          <InputAdornment position="start">
            {adornmentConfig.text}
          </InputAdornment>
        );
        inputProps.inputProps.autoFocus =
          this.props.question.autoFocus === true;
      } else {
        inputProps.endAdornment = (
          <InputAdornment position="end">{adornmentConfig.text}</InputAdornment>
        );
      }
    }

    return (
      <div ref={`question-${question.id}`}>
        <TextField
          id={`question-${question.id}`}
          name={name}
          value={textValue}
          variant="outlined"
          onChange={this.handleChange}
          type={type}
          className={`${styles.text} ${classNames(
            classes.margin,
            classes.textField
          )}`}
          InputProps={inputProps}
          onBlur={this.onBlur}
          label={!empty(question.label) ? question.label : null}
          placeholder={
            !empty(question.placeholder) ? question.placeholder : null
          }
        />
      </div>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, min, max, ...other } = props;

  other.type = "tel";

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      min={!empty(min) ? min : null}
      max={!empty(max) ? max : null}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}

FormTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(materialStyles)(FormTextField);
